import React, { useState, useEffect, useRef } from "react";
import "./App.css";

function App() {
  const [text, setText] = useState(localStorage.getItem("noteText") || "");
  const [fontSize, setFontSize] = useState(16);
  const [fontFamily, setFontFamily] = useState("Arial");
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showDropdown, setShowDropdown] = useState({
    file: false,
    edit: false,
    insert: false,
    view: false,
  });
  const [shareLink, setShareLink] = useState("");
  const [showSharePopup, setShowSharePopup] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const saveInterval = setInterval(() => {
      localStorage.setItem("noteText", text);
    }, 5000);

    return () => clearInterval(saveInterval);
  }, [text]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeAllDropdowns();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleTextChange = (e) => setText(e.target.value);

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const saveNote = () => {
    const blob = new Blob([text], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "note.txt";
    a.click();
    URL.revokeObjectURL(url);
  };

  const createShareLink = () => {
    const link = `${window.location.href}?note=${encodeURIComponent(text)}`;
    setShareLink(link);
    setShowSharePopup(true);
  };

  const toggleDropdown = (menu) => {
    setShowDropdown((prev) => ({
      file: false,
      edit: false,
      insert: false,
      view: false,
      [menu]: !prev[menu],
    }));
  };

  const closeAllDropdowns = () => {
    setShowDropdown({ file: false, edit: false, insert: false, view: false });
  };

  const handleDropdownItemClick = () => {
    closeAllDropdowns();
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareLink).then(() => {
      setShowSharePopup(false);
      alert("Link copied to clipboard!");
    });
  };

  return (
    <>
      <div className={`notepad ${isFullscreen ? "fullscreen" : ""}`}>
        <div className="toolbar" ref={dropdownRef}>
          {/* File, Edit, Insert, View Menus with Dropdowns */}
          <div className="menu">
            <button onClick={() => toggleDropdown("file")}>File ▼</button>
            {showDropdown.file && (
              <div className="dropdown file-dropdown">
                <button
                  onClick={() => {
                    setText("");
                    handleDropdownItemClick();
                  }}
                >
                  New
                </button>
                <button
                  onClick={() => {
                    saveNote();
                    handleDropdownItemClick();
                  }}
                >
                  Save
                </button>
                <button
                  onClick={() => {
                    window.print();
                    handleDropdownItemClick();
                  }}
                >
                  Print
                </button>
                <button
                  onClick={() => {
                    setText(localStorage.getItem("noteText") || "");
                    handleDropdownItemClick();
                  }}
                >
                  Open
                </button>
              </div>
            )}

            <button onClick={() => toggleDropdown("edit")}>Edit ▼</button>
            {showDropdown.edit && (
              <div className="dropdown edit-dropdown">
                <button
                  onClick={() => {
                    document.execCommand("cut");
                    handleDropdownItemClick();
                  }}
                >
                  Cut
                </button>
                <button
                  onClick={() => {
                    document.execCommand("copy");
                    handleDropdownItemClick();
                  }}
                >
                  Copy
                </button>
                <button
                  onClick={() => {
                    document.execCommand("paste");
                    handleDropdownItemClick();
                  }}
                >
                  Paste
                </button>
              </div>
            )}

            <button onClick={() => toggleDropdown("insert")}>Insert ▼</button>
            {showDropdown.insert && (
              <div className="dropdown insert-dropdown">
                <button
                  onClick={() => {
                    alert("Insert Image (coming soon)");
                    handleDropdownItemClick();
                  }}
                >
                  Image
                </button>
                <button
                  onClick={() => {
                    alert("Insert Table (coming soon)");
                    handleDropdownItemClick();
                  }}
                >
                  Table
                </button>
                <button
                  onClick={() => {
                    alert("Insert Link (coming soon)");
                    handleDropdownItemClick();
                  }}
                >
                  Link
                </button>
              </div>
            )}

            <button onClick={() => toggleDropdown("view")}>View ▼</button>
            {showDropdown.view && (
              <div className="dropdown view-dropdown">
                <button
                  onClick={() => {
                    toggleFullscreen();
                    handleDropdownItemClick();
                  }}
                >
                  Fullscreen
                </button>
              </div>
            )}
          </div>

          {/* Font Family, Font Size, and Share Button */}
          <select
            onChange={(e) => setFontFamily(e.target.value)}
            value={fontFamily}
            className="font-selector"
          >
            <option value="Arial">Arial</option>
            <option value="Times New Roman">Times New Roman</option>
            <option value="Courier New">Courier New</option>
          </select>
          <select
            onChange={(e) => setFontSize(Number(e.target.value))}
            value={fontSize}
            className="font-selector"
          >
            {[12, 16, 20, 24, 28].map((size) => (
              <option key={size} value={size}>
                {size}px
              </option>
            ))}
          </select>
          <button onClick={createShareLink} className="share-button">
            Share
          </button>
        </div>

        <textarea
          style={{
            fontFamily: fontFamily,
            fontSize: fontSize,
          }}
          value={text}
          onChange={handleTextChange}
          placeholder="Start typing..."
        />

        {showSharePopup && (
          <div className="share-popup">
            <p>Shareable Link:</p>
            <input type="text" readOnly value={shareLink} />
            <button onClick={copyToClipboard}>Copy to Clipboard</button>
          </div>
        )}
      </div>
      <div class="container">
        <h2 class="section-title">About Our Online Notepad</h2>
        <p class="section-content">
          Welcome to our feature-rich Online Notepad! Whether you're jotting
          down quick ideas, organizing thoughts, or drafting longer pieces, our
          notepad is built to handle all your writing needs. With a clean,
          intuitive design and a range of functionalities, it's the perfect
          digital alternative to a traditional notepad.
        </p>

        <h2 class="section-title">Features</h2>
        <div class="section-content section-features">
          <ul>
            <li>
              <strong>Rich Text Editing</strong> - Easily change font family and
              size to suit your preferences.
            </li>
            <li>
              <strong>File Options</strong> - Save your notes locally or print
              them for a hard copy.
            </li>
            <li>
              <strong>Sharing</strong> - Create a shareable link with a single
              click to share your notes with others.
            </li>
            <li>
              <strong>Fullscreen Mode</strong> - Maximize the notepad area for
              distraction-free writing.
            </li>
            <li>
              <strong>Persistent Storage</strong> - Your notes are saved in
              local storage so you can pick up where you left off.
            </li>
          </ul>
        </div>

        <h2 class="section-title">Privacy & Security</h2>
        <p class="section-content">
          We take your privacy and data security seriously. This online notepad
          saves your notes only on your local device, meaning no data is
          transmitted or stored on our servers. You have full control over your
          data, with options to delete your notes at any time.
        </p>
      </div>
    </>
  );
}

export default App;
